import React, { Component } from 'react';

// import legacy datastore
import DataStore from '../../../legacy/react/data/DataStore';
import Stores from './MeteoPanelStores';

// create app level react context 
const MeteoPanelContext = React.createContext();

// for overwriting set state 
var setState = Component.prototype.setState;

var randomColor = require('randomcolor'); // import the script

/**
 * Performs a deep merge of `source` into `target`.
 * Mutates `target` only but not its objects and arrays.
 *
 * @author inspired by [jhildenbiddle](https://stackoverflow.com/a/48218209).
 */

function mergeDeep(target, source) {
  const isObject = (obj) => obj && typeof obj === 'object';

  if (!isObject(target)) {
      return source;
  }
      
   if (!isObject(source)) {
    return target;
  }

  Object.keys(source).forEach(key => {
    const targetValue = target[key];
    const sourceValue = source[key];

    // if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
    //   target[key] = targetValue.concat(sourceValue);
    // } 
    
    if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}


function initializeStores(state) {

    // we could filter through measurements here 
    Stores['measurement'].setData(state.measurement);
    Stores['measurement_by_code'].setData(state.measurement);
    Stores['measurement_by_compound'].setData(state.measurement);
    Stores['physical_device'].setData(state.physical_device);
    Stores['site'].setData(state.site);
    //Stores['measurement_by_physical_device_id'].setData(state.measurement);

    Stores['chemical_compound'].setData(state.chemical_compound.map((c) => {

        // assign random color to every compound 
        return {
            ...c, compound_type: c.id, color: randomColor()
        }
    }));
}

var gTimeIds = {};

class MeteoPanelProvider extends Component {

    // define default state 
    state = {
        ui: {
            // place for references of objects, referenced by name  
            refs: {},

            charts: {
                selectedRange: "12h"
            },

            // default (live), or history 
            viewMode: "default", 

            historyMode: {

                // point in time 
                date: new Date(), 

                // date 
                now1h: 0, 

                // range we will use from ui.charts 
                cursor: "something"
            }
        },

        // measurements config table 
        measurement: [],

        // chemical compounds
        chemical_compound: [],

        // physical_device table 
        physical_device: [],

        // sites
        site: [],

        // app stores 
        stores: {},

        // indicates date of last data 
        now: null, now10m: null,

        // computed info data
        infoData: {},

        // wind data
        windData: {},

        // source, its used to indicate event source
        source: "",

        // ordered compound list 
        // similar to channels 
        // orderedCompoundList: [
        //     "pm10",
        //     "pm25",
        //     "pm2.5",
        //     "no2",
        //     "o3",
        //     "co",
        //     "so2",
        //     "hcho",
        //     "hcl",
        //     "hcn",
        //     "temp",
        //     "humid",
        //     "press",
        //     "rain"
        // ],
        // for odo the list is different 
        orderedCompoundList: [
            "odo",
            "nh3",
            "h2s",
            "parametr",
            "pm10",
            "pm25",
            "pm2.5",
            "temp",
            "humid",
            "press",
            "rain",
            "no2",
            "o3",
            "co",
            "so2",
            "hcho",
            "hcl",
            "hcn",
        ],

        // returns sorted list of site measurements 
        // sorting is done by chemical_compound.ord value 
        getSiteMeasurements: code => {
            //var compoundStore = this.state.stores['chemical_compound'];
            var site = this.state.stores['site'].getRecordById(code);
            var cl = this.state.orderedCompoundList;
            var measList = site.ds_map.map((code) => {
                return Stores['measurement_by_code'].getRecordById(code.replaceAll('_', '.'));
            }).filter((meas) => meas);

            //console.log('measurements for site:', 'code=', code, 'measlist=', measList, Stores['measurement_by_code'], site.ds_map)

            return (
                measList.sort((a, b) => {
                    return cl.indexOf(a.compound_type) - cl.indexOf(b.compound_type)
                })
            )
        },



        // returns sorted list of device measurements 
        // sorting is done by chemical_compound.ord value 
        getDeviceMeasurements: id => {
            //var compoundStore = this.state.stores['chemical_compound'];
            var measurements = this.state.stores['measurement_by_physical_device_id'];
            var dev = this.state.stores['physical_device'].getRecordById(id);
            var cl = this.state.orderedCompoundList;
            var measList = measurements.getRecordById(id);

            if (!measList && dev.ds_map) {
                measList = dev.ds_map.map((code) => {
                    return Stores['measurement_by_code'].getRecordById(code);
                }).filter((meas) => meas);
            }

            return (
                measList.sort((a, b) => {
                    return cl.indexOf(a.compound_type) - cl.indexOf(b.compound_type)
                })
            )
        },

        // some useful functions 
        // getMeasById
        getMeasByCode: (id) => {
            var code = id[0] === '_' ? id.substring(1).replace('_', '.') : id;
            var store = this.state.stores['measurement_by_code'];

            //console.log('ids: ',id, code, store.getIds())

            return store.hasId(code) ? store.getRecordById(code) : null;
        },

        getCompoundType: (id) => {
            var store = this.state.stores['chemical_compound'];

            return store.hasId(id) && store.getRecordById(id);
        },

        getStore: (name) => {
            return this.state.stores[name] ? this.state.stores[name] : null;
        },

        // we can use this function to update context 
        setState: (state) => {
            console.log('updating context state: ', state)

            this.setState(state);
        },

        // this can be used to avoid circular dependencies 
        updateState: (src, state) => {
            var token = src + "#" + new Date().getTime();

            // set token 
            state.source = token;

            // this will clear old timeout and its needed 
            try { clearTimeout(gTimeIds[src]); } catch (err) { }

            gTimeIds[src] = setTimeout(() => {
                this.setState(state)
                this.state.source = "";
            }, 1);

            // this token can be used to check the source of an event
            return token;
        }


    }


    setState(state, props) {
        console.log('setting new context state: ', state, this.state)

        // we can create stores here 
        if (state.measurement && (this.state.measurement.length === 0 || state.measurement.length > 0)) {
            initializeStores(state);
        }

        // finally we can call original set state function 
        setState.call(this, {
            ...state,
            // we need to do deep merge of ui branch, THIS IS EXPERIMENTAL!  
            ui: mergeDeep(this.state.ui, state.ui),
            stores: Stores
        });
    }

    render() {
        return <MeteoPanelContext.Provider value={this.state}>{this.props.children}</MeteoPanelContext.Provider>
    }
}


export {
    MeteoPanelContext, MeteoPanelProvider, Stores
};