// web tools for drawing svg icons:
// http://www.drawsvg.org/drawsvg.html
// to convert kml to geojson use: http://2geojson.com/index.php

import { MeteoPanelContext } from 'app/graphql/meteopanel/context/MeteoPanelContext';
import { Query } from 'react-apollo';

import createChartDataQuery from 'app/graphql/meteopanel/query/client/ChartData';
// legacy utilities libraries
import {
  buildSerieSpec, dateToUts, roundDate, nextDate, buildQueryVars
} from 'app/graphql/fey/data/FeyUtils';


import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Map, Marker, Popup, TileLayer, Tooltip, GeoJSON } from 'react-leaflet';
import { Icon as LeafletIcon, Marker as LeafletMarker } from 'leaflet';



//import { Card, Icon, Collapse, List, Avatar } from 'antd';

import { processResponse, getFetchParams } from 'app/legacy/react/components/chart/Utils.jsx';

import { computeInfoData } from 'app/legacy/react/eusluga/actions/infoData.jsx';

import 'leaflet/dist/leaflet.css';

import {
  mapParTypeName, mapParTypeNameToTag, mapUnitNameToTag, mapUnitName, tickFormatter, LegendComponent, ColorTable
} from 'app/legacy/react/core/CoreUtils.jsx';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import * as Armaag from './qualityindex/Armaag.jsx';
import { getIndexIcon, Info } from 'app/legacy/react/eusluga/components/qualityindex/Krapkowice.jsx';

// import tw from "twin.macro"
import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react'


//const Panel = Collapse.Panel;
const Punkty = require('app/graphql/meteopanel/kml/Ustalone.json');

const MeteoStationIcon = new LeafletIcon({
  className: 'meteo-station-icon',
  iconUrl: require('app/images/meteo_station.svg').default,
  iconSize:    [36, 36]
});

// set default icon 
let DefaultIcon = new LeafletIcon({
  iconUrl: icon,
  // shadowUrl: iconShadow
  iconSize: [12, 24]
});

LeafletMarker.prototype.options.icon = DefaultIcon;

var k = 0;
function setRefElement(el) {
  // console.log('map element: ', k++, el.containerPoint, el.target._container.offsetWidth / 2, el.target._container.offsetHeight / 2, [el.latlng.lat, el.latlng.lng])
}


// var loaderImage = require('app/images/chart-loader.gif').default;

var LoaderComp = () =>
  <Dimmer active inverted>
    <Loader inverted>Ładowanie danych...</Loader>
  </Dimmer>
//  <div style={{position: 'absolute',top: 0, left: 'calc(50% - 30px)', background: '#eee', opacity: 0.7 }}> 

// TODO: update coordinates for meta locations 

const GoogleMap = ({ ctx, position, store, locations, infoData, mode, zoom, kmlData, homepage, Info, mapTypeId }) =>
  <Map onMouseMove={setRefElement} center={position} zoom={zoom} style={{ width: "100%", position: 'absolute', minHeight: 200, bottom: '0px', top: '0px' }}>
    {/* {kmlData ? <GeoJSON  data={kmlData} style={{color: 'black', fillColor: '#aaa', opacity: 0.6, weight: 1}}/>: null} */}
    <TileLayer
      //url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      url={`https://mt0.google.com/vt/lyrs=${mapTypeId}&hl=en&x={x}&y={y}&z={z}&s=Ga`}
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    />
    {
      locations.map((r, i) => {
        console.log('creating popup for: ', ctx, r, infoData[r.code])
        var tooltip = r.code;
        var icon = getIndexIcon(r, infoData[r.code]);

        if (['w1214', 'w1799'].indexOf(r.code) > -1) {
          // wind station we need different icon
          icon = MeteoStationIcon;
        }

        return (
          <Marker key={i} position={r.geometry.coordinates.slice(0, 2).reverse()} icon={icon}>
            <Popup style={{ minWidth: 200, zIndex: 11000 }}>
              <Info ctx={ctx} loc={r} data={infoData[r.code]} />
            </Popup>
            <Tooltip >{tooltip}</Tooltip>
          </Marker>
        )
      })
    }
  </Map>;


// h = roads only
// m = standard roadmap
// p = terrain
// r = somehow altered roadmap
// s = satellite only
// t = terrain only
// y = hybrid
const MapTypes = {
  roadsonly: 'h',
  standard: 'm',
  terrain: 'p',
  roadmap: 'r',
  sattelite: 's',
  hybrid: 'y'
}





class LocationsMap extends Component {

  constructor(props) {
    super(props);

    //this.headerRenderer = new HeaderRenderer();
    //this.cellRenderer = new CellRenderer();
    this.state = {
    };
  }


  componentWillMount() {

    //store.dispatch(requestPogodynkaHtml(this.props.dataProvider))
  }

  shouldRender(data, ctx, loading) {

    if (this.lastChartData === data.chartData && this.cachedRenderedResult && this.now == ctx.now10m && this.now1h == ctx.ui.historyMode.now1h && this.loading == loading) {
      //  we should use cache 
      return false;
    }

    return true;
  }

  shouldCompute(inputData) {

  }

  generateComponent(ctx, loading, error, data) {
    var props = this.props;

    console.log('generate location component: ', loading, data)

    //const position = [54.52961166644193, 18.5519814491272];
    //const position = [54.52961166644193, 18.5549814491272];
    //const position = [54.52591340237219, 18.554449081420902];
    //const position = [52.16138, 20.77466];
    // const position = [50.5035, 18.9271];
    //const position = [50.344566, 19.288913];
    //const position = [50.330463120416304,18.827090];
    // const position = [50.334228, 18.831785];
    //const position = [50.038412,	18.490313];
    const position = ctx.site[0].geometry.coordinates.slice(0, 2).reverse();

    console.log('my position: ', position, ctx.site[0])

    var zoom = 16;
    var store = props.store;
    var infoData = this.props.infoData;
    var mode = this.props.mode;

    var mapTypeId = "y";
    var infoData;

    var mapProps = {
      position, zoom, store, infoData, mapTypeId, mode, Info
    }


    return (
      this.memoizedComponent =
      <>
        {this.infoData ? <GoogleMap {...mapProps} ctx={ctx} locations={ctx.site} infoData={this.infoData} /> : null}
        {loading ? <LoaderComp /> : null}
      </>
    )

  }

  generateView(ctx, { loading, error, data, networkStatus, client, variables }) {
    if (error) return `Error! ${error.message}`;


    let params = [loading, data, ctx.ui.viewMode, ctx.ui.historyMode.now1h, ctx.now10m];

    console.log('generate location view: ', params, this.lastParams)

    if (this.memoizedComponent) {
      if (this.lastParams && !this.lastParams.some((v, i) => v !== params[i])) {
        // we should use memoized component 
        return this.memoizedComponent;
      }
    }

    // save last params 
    this.lastParams = params;

    if (data) {

      // prepare data 
      for (var i = 1; i <= data.chartData.length; i++) {

        var properValue = false;
        var lastValue = data.chartData[data.chartData.length - i];

        // check for proper value 
        for (var p in lastValue) {
          if (p !== 't' && p !== '__typename') {
            if (lastValue[p] !== '') {
              // we assume that there is a value for this param 
              properValue = true;
            }
          }
        }

        if (ctx.ui.viewMode == "history" && lastValue) {
          if (lastValue.t > ctx.ui.historyMode.now1h) {
            continue
          }
        }

        //properValue = true;
        if (properValue) {

          this.lastChartData = data.chartData;
          var relevantData = this.lastChartData.slice(0, this.lastChartData.length - i + 1)

          this.infoData = computeInfoData(ctx, relevantData)


          // save this to compare for future
          this.now = ctx.now10m;
          this.now1h = ctx.ui.historyMode.now1h;

          //console.log("TIMING: ", "resetting infoData", i, relevantData[relevantData.length - 1].t)

          // setTimeout(() => ctx.setState({ 
          //   relevantData: relevantData,
          //   infoData: this.infoData 
          // }), 1)

          //console.log('token test', '-', this.token, '-', ctx.source , '-', this.token === ctx.source, (ctx.source !== "" && this.token !== ctx.source) || typeof this.token == 'undefined', this.infoData, relevantData)

          if (ctx.source === "" || this.token !== ctx.source) {
            console.log('token test', this.token, ctx.source , this.token === ctx.source)
            this.token = ctx.updateState("location", {
              relevantData: relevantData,
              infoData: this.infoData
            });
          } else {
            console.log('token test else', this.token, ctx.source , this.token === ctx.source)

          }

          break;
        }
      }

      if (this.lastChartData)
        return this.generateComponent(ctx, loading, error, data)

    }

    //return this.cachedRenderedResult || null;
    return this.generateComponent(ctx, loading, error, data)
  }


  render() {

    var {
      dataUrl
    } = this.props;


    // console.log('rendering: ', locations, this.props.infoData, this.props.infoData["KK21"]  && typeof this.props.infoData["KK21"].qualityIndex != "undefined")
    return (
      <div style={{ width: "100%", position: 'absolute', minHeight: 200, bottom: '0px', top: '0px' }}>
        <MeteoPanelContext.Consumer key={"map"}>
          {(context) => {
            var step = '1h';
            //var columns = context.measurement.filter((meas) => ["pm10", "pm2.5"].indexOf(meas.compound_type) > -1).map((meas) => "_" + meas.code);
            var columns = context.measurement.filter((meas) => meas.showInPopup).map((meas) => "_" + meas.code);
            var uts1 = (context.now10m) - 1 * 3600, uts2 = context.now10m;
            //var uts1 = (context.now) - 1 * 3600, uts2 = context.now;

            if (context.ui.viewMode == "history") {
              var now1h = context.ui.historyMode.now1h || context.now10m;
              uts1 = now1h - 100;
              uts2 = now1h;
            }

            // i just should do caching on this level 
            // console.log('querying for data for location map: ', context.ui.historyMode, context.ui.viewMode, new Date(uts1 * 1000), new Date(uts2 * 1000))

            var rangeHours = 12;

            switch (context.ui.charts.selectedRange) {
              case "12h":
                rangeHours = 12;
                break;
              case "3d":
                rangeHours = 24 * 3;
                break;
              case "7d":
                rangeHours = 24 * 7;
                break;
            }

            //    uts1: context.now10m - rangeHours * 3600, uts2: context.now10m,

            if (context.ui.viewMode == "history") {
              uts1 = context.now10m - rangeHours * 3600;
              uts2 = context.now10m;
            }

            //console.log("location getting data: ", uts1, uts2)

            //console.log('what are my columns: ', context.measurement.filter((meas) => meas.showInPopup))

            return (
              context.measurement.length && context.now ?
                <Query fetchPolicy={"network-only"} query={
                  createChartDataQuery({
                    //mem: 1,
                    dataType: 'averages',
                    dataUrl,
                    now1h,
                    //       uts1: context.now10m - 1 * 3600, uts2: context.now10m - 800,
                    uts1: uts1, uts2: uts2,
                    step,
                    columns: columns,
                    vars: buildQueryVars(columns, step, context.measurement), // server query vars
                    context // pass context to query function 
                  })
                }>{this.generateView.bind(this, context)}</Query> : <LoaderComp />
            )

          }}
        </MeteoPanelContext.Consumer>
      </div>
    )
  }
}


export default LocationsMap;
