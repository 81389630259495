// bootstrap code goes here
import React, { Component } from 'react';
import { ResizeSensor } from 'css-element-queries';
import { MeteoPanelContext } from '../context/MeteoPanelContext';
import { Query } from 'react-apollo';

// legacy utilities libraries
import {
  buildSerieSpec, dateToUts, roundDate, nextDate, buildQueryVars, buildQueryVarsWithTypes
} from '../../fey/data/FeyUtils';

// legacy utilities libraries
import {
  processResponse
} from '../../fey/data/ChartUtils';

import { decimals, mapParTypeName } from '../../../legacy/react/core/CoreUtils';

import createRawDataQuery from '../query/client/RawData';
import createWindDataQuery from '../query/client/WindData';


import { createAnimatedArrow } from './windboard/Arrow'
import { createAnimatedRing } from './windboard/Ring'
import { drawDisk } from './windboard/Disk';

 import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react'


// require('object-assign');
var Moment = require('moment');
Moment.locale('pl');

//var loaderImage = require('app/images/loading.gif').default;

// var LoaderComp = () => <div style={{ border: '1px solid black', height: 280 }}>
  {/* <img style={{ position: 'relative', left: '50%', top: '50%', marginLeft: -10, marginTop: -10 }} src={loaderImage} /> */}
{/* </div>; */}


// var loaderImage = require('app/images/chart-loader.gif').default;

var LoaderComp = () =>
    <Dimmer active inverted>
        <Loader inverted>Ładowanie danych...</Loader>
    </Dimmer>


// skala beauforta 
// B = 1,127·V2/3 (dla V w m/s) = 0,723·V2/3 (dla V w węzłach)[2].

const WindLabels = ({ windData, size }) => {
  var halfWidth = size.width / 2, halfHeight = size.height / 2;

  const textStyle = {
    position: 'absolute',
    // default 18px
    fontSize: '14px',
    fontWeight: 'bold'
  };

  var margin = 5;

  console.log('wind labels: ', windData)

  const labels = [
    { key: 'ws_min', style: { left: margin, top: margin }, text: windData.ws_i10m10s.val, unit: 'm/s' },
    { key: 'ws_max', style: { right: margin, top: margin }, text: windData.ws_x10m10s.val, unit: 'm/s' },
    { key: 'wd_min', style: { left: margin, bottom: margin }, text: windData.wd_i10m10s.val, unit: '&deg;' },
    { key: 'wd_max', style: { right: margin, bottom: margin }, text: windData.wd_x10m10s.val, unit: '&deg;' },

    // { key: 'ws_min', style: { left: margin, top: margin }, text: windData.ws.min, unit: 'm/s' },
    // { key: 'ws_max', style: { right: margin, top: margin }, text: windData.ws.max, unit: 'm/s' },
    // { key: 'wd_min', style: { left: margin, bottom: margin }, text: windData.wd.min, unit: '&deg;' },
    // { key: 'wd_max', style: { right: margin, bottom: margin }, text: windData.wd.max, unit: '&deg;' },

    // { key: 'ws_val', style: { left: halfWidth, top: halfHeight - 10, display: 'block', transform: 'translate(-50%, -50%)' }, text: windData.ws_10m.val, unit: 'm/s' },
    // { key: 'wd_val', style: { left: halfWidth, top: halfHeight + 10, display: 'block', transform: 'translate(-50%, -50%)' }, text: windData.wd_10m.val, unit: '&deg;' },
    { key: 'ws_val', style: { left: halfWidth, top: halfHeight - 10, display: 'block', transform: 'translate(-50%, -50%)' }, text: windData.ws.val, unit: 'm/s' },
    { key: 'wd_val', style: { left: halfWidth, top: halfHeight + 10, display: 'block', transform: 'translate(-50%, -50%)' }, text: windData.wd.val, unit: '&deg;' },


    // {key: 'desc', style: {left: halfWidth, top: halfHeight, display: 'block', transform: 'translate(-50%, -50%)'}, text: '10min', unit: ''}
  ]

  var ws_b = 1.127 * Math.pow(windData.ws.val, 2.0 / 3.0);


  return (
    labels.map((l) => {
      return (
        l.key == "ws_val" ?
          <div key={l.key} style={{ ...textStyle, ...l.style }} dangerouslySetInnerHTML={{
            __html: typeof l.text === 'string' ? l.text : decimals(l.text, 1) + ' ' + l.unit + " (<b>" + decimals(ws_b, 1) + "B</b>)"
          }}
          ></div>
          :
          <div key={l.key} style={{ ...textStyle, ...l.style }} dangerouslySetInnerHTML={{ __html: typeof l.text === 'string' ? l.text : decimals(l.text, 1) + ' ' + l.unit }}></div>
      )
    })
  )
}

// min max dla prędkości 
// min max dla kierunku wiatru 
// dane 1 godzinne (średnie za okres)
// dane 10 minutowe (bieżące)

var d3 = require('d3');

var ReactFauxDOM = require('react-faux-dom')

class LiveWindBoard extends Component {

  state = {
  }

  createFauxBoard(series, size) {

    var svg = d3.select(ReactFauxDOM.createElement('svg')).attr({
      // width: size.width, height: size.height,
      position: 'absolute'
    }).style({
      width: '100%',
      height: '100%'
    })


    var node = svg.node();
    this.svg = node;

    // this is the safest way to assign react events
    //node.props.onMouseMove = this.onSvgMouseMove.bind(this);
    this.testD3DPathWithArc(svg, size);

    return node.toReact();
  }

  startSizeDetector() {

    console.log('size detector: ', this.svg)
    if (this.svg) {

      this.resizeSensor = new ResizeSensor(this.container, () => {

        // var width = this.container.clientWidth;
        // var height = this.container.clientHeight;

        // console.log('rozmiary komponentu: ', this.tableContainer.offsetHeight, this.tableContainer.offsetWidth)
        this.forceUpdate();
      })
    } else {
      setTimeout(this.startSizeDetector.bind(this), 50);
    }
  }

  componentWillMount() {
    // this.startSizeDetector();
  }

  onResize() {
    this.forceUpdate();
  }

  parseWindData(rawData) {
    var sample = rawData[rawData.length - 1];
    var a, v, s;

    var result = {
      ws_10m: {
        val: null, min: null, max: null
      },
      wd_10m: {
        val: null, min: null, max: null
      }
    }

    for (var name in sample) {
      // skip time 
      if (name === 't') continue;

      v = null;

      if (/.*WS_V10m.*/.test(name)) {
        v = result.ws_10m;
      } else if (/.*WD_V10m.*/.test(name)) {
        v = result.wd_10m;
      } else {
        // we dont want to process other variables 
        continue
      }


      // get annotation from other samples 
      s = sample[name];

      a = s.a.split('_');

      if (v) {
        v.val = s.v;
        v.min = parseFloat(a[a.indexOf('l') + 1]);
        v.max = parseFloat(a[a.indexOf('g') + 1]);
      }
    }

    //  console.log('ok, we have wind data: ', result)
    return result;
  }

  parseAdditionalData(rawData) {
    var sample = rawData[rawData.length - 1];
    var a, v, s;

    var result = {

    }

    for (var name in sample) {
      // var affix = name.substring(7).toLocaleLowerCase();
      var affix = name.substring(1).split('_').slice(1).join('_').toLocaleLowerCase();

      // skip time 
      if (name === 't') continue;
      if (name === '__typename') continue;

      var v = { val: null, min: null, max: null };

      //console.log('what is my sample additional: ', sample)


      // get annotation from other samples 
      s = sample[name];

      if (v) {
        //  console.log('setting sample: ', name, s.v)
        v.val = s.v;

        if (s.a) {
          a = s.a.split('_');
          v.min = parseFloat(a[a.indexOf('l') + 1]);
          v.max = parseFloat(a[a.indexOf('g') + 1]);
        }
      }
//      console.log('what is my sample additional: ', sample, name, affix, v)

      result[affix] = v;

    }

    //  console.log('ok, sample we have wind data: ', result)
    return result;
  }

  // test for drawing arcs 
  testD3DPathWithArc(svg, size) {

    svg.append('g')
      .append('rect')
      .style('fill', 'none')
      .attr({
        x: 0, y: 0, width: size.width, height: size.height
      })


    if (this.lastWindData) {

      //this.testRing(svg, size, 0, 'white', 1, { startAngle: 0, endAngle: 360 })
      //this.testRing(svg, size, 0, 'blue', 1, { startAngle: (this.lastWindData.wd_i9m10s.val), endAngle: (this.lastWindData.wd_x10m10s.val) });
      if (!this.ringPainter) {
        this.ringPainter = createAnimatedRing();
      }

      console.log('my last wind data: ', this.lastWindData)
      this.ringPainter({
        svg, size, level: 0, color: 'blue', thickness: 1,
        startAngle: this.lastWindData.wd_i10m10s.val, endAngle: this.lastWindData.wd_x10m10s.val
      })

      drawDisk({ svg, size })
      //this.testDisk(svg, size);

      // 7.5 for radius, 15 for diameter
      //this.testArrow(svg, size, this.lastWindData.wd.val, (this.lastWindData.ws.val / (7.5)));

      if (!this.arrowPainter) {
        this.arrowPainter = createAnimatedArrow()
      }

      this.arrowPainter({
        svg, size, angle: this.lastWindData.wd.val, length: this.lastWindData.ws.val / 7.5
      })

    }

  };

  generateView(ctx, { loading, error, data }) {
    if (error) return `Error! ${error.message}`;

    // if (loading) {
    //   return <LoaderComp />
    // }

    //console.log('windboard: ', this.token, ctx.source)

    if (!loading && data) {

      this.lastWindData = {
        ...this.parseWindData(data.rawData),
        ...this.parseAdditionalData(data.additionalData),
        ...this.parseAdditionalData(data.additionalAveragesData)
      }

      //console.log('we have wind data event', data,  ctx.source, this.source)
      if (ctx.source === "" || this.source != ctx.source) {

        console.log('updating my wind data: ', this.lastWindData, data)
        // update context state, but beware
        this.source = ctx.updateState('wind', { windData: this.lastWindData });
      } else {
        //return null;
      }
    }

    var size;

    if (this.svg) {
      var bbox = this.svg.getBoundingClientRect();

      if (bbox) {
        size = {
          width: bbox.width,
          height: bbox.height
        }
      }
    }

    if (!(size && size.width)) {
      if (this.container) {
        size = { width: this.container.clientWidth, height: this.container.clientHeight };
      }
    }

    // this is just  for case when we are using hotplug reloading to update the content an applicatino during development 
    // default 280 x 280
    if (!(size && size.width)) {
      size = this.props.defaultSize || { width: 200, height: 200}
    }

    var faux = this.createFauxBoard(this.lastWindData, size);

    //console.log('rendering windrose: ', this.lastWindData, size, this.svg, this.svg.getBoundingClientRect())

    //debugger
    return (
      <div ref={(r) => this.container = r} style={{ padding: 0, margin: 0, width: '100%', height: '100%', background: '#ffffff', opacity: 1, borderRadius: 10 }}>
        {this.lastWindData ? <WindLabels size={size} windData={this.lastWindData} /> : null}
        {faux}
      </div>
    )
  }


  render() {
    var {
      dataUrl
    } = this.props;

    var step = '10m';

    var vars = buildQueryVarsWithTypes(['_psm1_WD', '_psm1_WS'], ['V'], step);

    var vars2 = buildQueryVarsWithTypes(['_psm1_WD', '_psm1_WS'], ['V'], '10s');

    var vars3 = [
      ...buildQueryVarsWithTypes(['_psm1_WD', '_psm1_WS'], ['V', 'I', 'X'], '1m10s'),
      ...buildQueryVarsWithTypes(['_psm1_WD', '_psm1_WS'], ['V', 'I', 'X'], '2m10s'),
      ...buildQueryVarsWithTypes(['_psm1_WD', '_psm1_WS'], ['V', 'I', 'X'], '10m10s')
    ];

    // add underscore back 
    var columns = vars.map((v) => v.replace(/"/g, '').replace(/:/g, '_'));
    var columns2 = vars2.map((v) => v.replace(/"/g, '').replace(/:/g, '_'));
    var columns3 = vars3.map((v) => v.replace(/"/g, '').replace(/:/g, '_'));

    console.log('wind request columns: ',columns, columns2, columns3)



    return (
      <MeteoPanelContext.Consumer key={"wind"}>
        {(context) => {
          return (
            context.measurement.length && context.now  && context.ui.viewMode == "default" ?
              <Query query={
                createWindDataQuery({
                  mem: 1,
                  dataType: 'averages',
                  dataUrl,
                  uts1: context.now10m, uts2: context.now10m,
                  now: context.now,
                  step,
                  columns, columns2, columns3,
                  // server query vars
                  vars, vars2, vars3,
                  context, // pass context to query function 
                  processFunc: (response, step) => processResponse(response, step, 0)
                })
              }>{this.generateView.bind(this, context)}</Query> : (context.ui.viewMode  == "default" ? <LoaderComp /> : null) 
          )
        }}
      </MeteoPanelContext.Consumer>
    )
  }
}


export default LiveWindBoard;
