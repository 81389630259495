
// react 
import React, { Component } from 'react';

// apollo client and grqphql 
import ApolloClient, { InMemoryCache, gql } from 'apollo-boost';
import { ApolloProvider, Query } from 'react-apollo';

// legacy libraries (rebiel.git)
import Chart from '../legacy/react/components/Chart.jsx';
import LocationsMap from '../legacy/react/eusluga/components/LocationsMap.jsx';


// legacy utilities libraries
import {
    buildSerieSpec, dateToUts, roundDate, nextDate, buildQueryVars
} from './fey/data/FeyUtils';

// layout libraries
import GridLayout from 'react-grid-layout';


// import queries
import createTableDataQuery from './meteopanel/query/client/TableData';
import createInitialDataQuery from './meteopanel/query/client/InitialData';
import createRawDataQuery from './meteopanel/query/client/RawData';


// import new components
import LiveDataTable from './meteopanel/components/LiveDataTable'
import LiveDataChart from './meteopanel/components/LiveDataChart'
import LiveDataChecker from './meteopanel/components/LiveDataChecker'
import LiveWindBoard from './meteopanel/components/LiveWindBoard'
import WindStatsTable from './meteopanel/components/WindStatsTable'

import { ALL_LOCATIONS_QUERY } from './meteopanel/query/client/UtilityQuery'


// import context 
import { MeteoPanelContext, MeteoPanelProvider } from './meteopanel/context/MeteoPanelContext';
import { typeDefs } from './meteopanel/schema/typedefs'
import createTestQuery from './meteopanel/query/client/TestNestedQuery.js';

import { MemoryCacheFieldsPolicy } from './meteopanel/cache/MemoryCacheFieldsPolicy'

import { computeInfoData } from '../legacy/react/eusluga/actions/infoData'

import TopBar from './meteopanel/components/TopBar'


var Moment = require('moment');
Moment.locale('pl');


// lodash 
const { find, filter, map } = require('lodash');


// TODO: move this to separate file 
/**
 * Performs a deep merge of `source` into `target`.
 * Mutates `target` only but not its objects and arrays.
 *
 * @author inspired by [jhildenbiddle](https://stackoverflow.com/a/48218209).
 */

function mergeDeep(target, source) {
  const isObject = (obj) => obj && typeof obj === 'object';

  if (!isObject(target)) {
      return source;
  }
      
   if (!isObject(source)) {
    return target;
  }

  Object.keys(source).forEach(key => {
    const targetValue = target[key];
    const sourceValue = source[key];

    // if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
    //   target[key] = targetValue.concat(sourceValue);
    // } 
    
    if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}



//var WindBackground = require('app/images/windrose.png').default;


//console.log('wind background: ', WindBackground)
function buildVirtualMeasurements(collections, measurements) {
    var result = [];
    var id = -1;

    collections.forEach((c) => {
        var measList = [];
        var obj = {};

        c.codes.forEach((r) => {
            var re = new RegExp(r);

            measList.push(...measurements.filter((m) => {
                //if (re.test(m.code) && obj == null) {
                //    obj = Object.assign({}, m);
                //}
                //console.log('collection testing measurement: ', m.code)
                console.log('checking for collection: ', re, m.code.toUpperCase(), re.test(m.code.toUpperCase()))
                return re.test(m.code.toUpperCase());
            }))

        })

        result.push(Object.assign(obj, {
            id: id--,
            code: c.name,
            collection: measList,
            virtual: true
        }))

    });

    return result;
}



// apollo client configuration 
const cache = new InMemoryCache(
    // we can define client cache policies 
    MemoryCacheFieldsPolicy
);

console.log('collection memory cache fields policy: ', cache);

const client = new ApolloClient({
    cache: cache,
    clientState: {
        defaults: {
            chartData: [],
            tableData: [],
            location: []
        },
        resolvers: {
            Query: {
                ...require('./fey/resolvers/chartData').default.Query,
                ...require('./fey/resolvers/tableData').default.Query,
                ...require('./fey/resolvers/location').default.Query
            }
        }
    },
    //   link: new SchemaLink({ schema }),
    defaultOptions: {
        query: {
            fetchPolicy: 'cache-and-network',
            errorPolicy: 'all'
        }
    },

    // define schema 
    //typeDefs
});

// using query manualy 
// onClick={async () => {
//     const { data } = await client.query({
//       query: GET_DOG_PHOTO,
//       variables: { breed: 'bulldog' },
//     });
//     setDog(data.dog);
//   }}

//const Punkty = require('app/graphql/meteopanel/kml/Ustalone.json');


class MeteoPanel extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }

        // poborniki pyłów w gdynii 
        // 00 - 10
        // HES00_PA, _PM10, _PM25, _RH, _TA

        this.variables = ['_ps01_odo', '_ps01_nh3', '_ps01_h2s', '_ps01_f02', '_ps01_f03', '_ps01_f12', '_ps01_pm10', '_ps01_pm25', '_ps01_ta',  '_ps01_rh', '_ps01_pa', ];

        this.tableViewConfig = [
            { type: 'text', dataKey: 'date' },
            { type: 'text', dataKey: 'time' },
            { type: 'value', header: 'Odory', dataKey: '_ps01_odo', unit: 'ppb' },
            { type: 'value', header: 'Amoniak', dataKey: '_ps01_nh3', unit: 'ppb' },
            { type: 'value', header: 'Siarkowodór', dataKey: '_ps01_h2s', unit: 'ppb' },
            { type: 'value', header: 'TGS02', dataKey: '_ps01_f02', unit: 'ppb' },
            { type: 'value', header: 'TGS03', dataKey: '_ps01_f03', unit: 'ppb' },
            { type: 'value', header: 'TGS12', dataKey: '_ps01_f12', unit: 'ppb' },
            { type: 'value', header: 'PM10', dataKey: '_ps01_pm10', unit: 'ppb' },
            { type: 'value', header: 'PM25', dataKey: '_ps01_pm25', unit: 'ppb' },
            { type: 'value', header: 'Temperatura', dataKey: '_ps01_ta', unit: '&deg;C' },
            { type: 'value', header: 'Wilgotność', dataKey: '_ps01_rh', unit: '%' },
            { type: 'value', header: 'Ciśnienie', dataKey: '_ps01_pa', unit: 'hPa' },
            // { type: 'value', header: 'Punkt rosy', dataKey: '_sps01_HI', unit: '&deg;C' },
            // { type: 'value', header: 'Opad', dataKey: '_sps01_RAIN', unit: 'mm' },
        ]

        this.tableViewData = {
            rosa: 12.4, temp: 25.4, humid: 76, press: 1106, rain: 1.2, date: '2019-06-15', time: '15:30:16'
            //rosa: '-',  temp: '-', humid: '-', press: 1106, rain: 1.2, date: '2019-06-15', time: '15:30:16'
        }

        this.tabsConfig = [
            //{ name: "Wiatr", measurements: ["_w1214_WS", '_w1214_WD'] }, { name: "Temperatura", measurements: ["_s2192_TA"] }, { name: "Ciśnienie", measurements: ['_s2192_PA'] }, { name: "Opad", measurements: ['_s2192_RAIN'] }
            // { name: "Wiatr", measurements: ["_w1891_WS", '_w1891_WD'] }, { name: "Temperatura", measurements: ["_ps01_TA"] }, { name: "Ciśnienie", measurements: ['_ps01_PA'] }, { name: "Wilgotność", measurements: ['_ps01_RH'] } 
            { name: "Temperatura", measurements: ["_ps01_ta"] }, { name: "Ciśnienie", measurements: ['_ps01_pa'] }, { name: "Wilgotność", measurements: ['_ps01_rh'] } 
            //, { name: "Wiatr", measurements: ["_ps01_WS", '_ps01_WD'] }
        ]

        this.pmChartsConfig = [
        ];

        // for (var i = 0; i <= 10; i++) {
        //     var str = "" + i;

        //     this.pmChartsConfig.push({
        //         name: `Pył zawieszony na stacji HES ${str}`,
        //         code: ["PM10", "PM25"].map((meas) => `_HES${str.padStart(2, 0)}` + "_" + meas)
        //     })
        // }

        // console.log('pm chart config: ', this.pmChartsConfig)

    }

    computeHistoryInfoData(selection) {
        // iterate 
        var q = selection.series[0].q;
        var d = {};

        for (var name in this.ctx.state.ui.refs) {
            if (/pm_[0-9]+/.test(name)) {
                var chartRef = this.ctx.state.ui.refs[name];

                mergeDeep(d, chartRef.lastChartData[q]);
            }
        }

        //console.log('getting interesting data from charts: ', d)

        //return computeInfoData(this.ctx.state, d);
        return d;
    }

    onFirstChartMouseMove(selection) {
        let state = this.ctx.state;
        if (state.ui && state.ui.viewMode == "history") {

            // but we need to compute infoData for a given point so, lets ge the values
            //var infoData = this.computeHistoryInfoData(selection);

            console.log('we have a selection: ', selection.series[0].q, selection.event.nativeEvent.offsetX, this.ctx )
            this.ctx.setState({
                ui: {
                    historyMode: {
                        selection: selection,
                        //now1h: infoData.t
                        now1h: Math.round(selection.series[0].t/1000)
                    }
                }
            })
        }
    }

    componentWillMount(props) {
    }

    render(props) {

        console.log('rendering: ')
        return (
            <MeteoPanelProvider ref={(ref) => this.ctx = ref}>
                <ApolloProvider client={client}>
                    {/* <Query query={createTestQuery({dataUrl: this.props.dataUrl})} skip={!this.initialized} fetchPolicy={"cache-only"}>
                        {({ loading, error, data, context, refetch }) => {
                            console.log("Hello from test query: ", loading, error, data)
                            if (!data) return null
                            if (loading) return null;
                            if (error) {
                                return `Error! ${error.message}`;
                            }

                            console.log("Hello from test query: ", data)
                            return null
                        }}
                    </Query> */}
                    <Query  skip={!this.initialized} fetchPolicy="cache-first" query={ALL_LOCATIONS_QUERY}>
                        {( {loading, error, data, context, refetch }) => {

                            //console.log('running polling collection query: ', new Date(), loading, error, data, context)
                            console.log('running polling collection query: ', new Date(), loading)
                            if (data) {
                                console.log('my collection query: ', data);
                            }

                            return null;
                        }}
                    </Query>

                    {/* query for measurements data */}
                    <Query query={createInitialDataQuery({ dataUrl: this.props.dataUrl, metaUrl: process.env.PUBLIC_URL, metajson: 'zut.meta.json', gid: 'zut' })} skip={this.initialized}>
                        {({ loading, error, data, context, refetch }) => {
                            if (!data) return null
                            if (loading) return null;
                            if (error) {
                                return `Error! ${error.message}`;
                            }

                            this.initialized = true;
                            
                            var additionalMeasurements = [];

                            // initial sort of measurements
                            var metaDevices = data.metajson.filter((loc) => loc.lat).map((loc, i) => {
                                var id = -1000 + i;
                                var dsMap = [];

                                for (var name in loc.ds) {
                                    if (additionalMeasurements.indexOf(loc.ds[name]) == -1) {
                                        additionalMeasurements.push(loc.ds[name])
                                    }

                                    //dsMap.push(loc.ds[name]);
                                    dsMap.push(loc.ds[name].replace('.', '_'));
                                }

                                var mappedDs = {};
                                for (var dsName in loc.ds) {
                                   mappedDs[dsName] = loc.ds[dsName].replace('.', '_')
                                }

                                //  id name device_type_id station_id protocol address conn_args active
                                return { 
                                    ...loc,
                                    id: id,
                                    code: loc.site,
                                    device_type_id: "metajson",
                                    station_id: 9999,
                                    protocol: "metajson",
                                    address: "metajson",
                                    conn_args: "metajson", 
                                    active: true,
                                    ds_map: dsMap,
                                    ds: mappedDs,

                                    // just for compatibility now 
                                    geometry: {
                                        coordinates: [loc.lon, loc.lat, 0]
                                    }
                                }
                            })                  


                            
                            // add locations, since there are no locations defined in response right now 
                            data.site = metaDevices.sort((a, b) => {
                                return a.name > b.name ? 1 : (a.name == b.name ? 0 : -1)
                            })

                            var outsideMeasurements = data.measurement.filter(
                                (meas) => {
                                    return /s9999[1-4].*/.test(meas.code)
                                }
                            )

                            console.log('what are my meta devices: ',  metaDevices, additionalMeasurements, outsideMeasurements)

                            //console.log('what are my devices: ', data.physical_device, metaDevices)
                            data.measurement = data.measurement.filter(
                                (meas) => {
                                    // messy but fuck it
                                    var showInPopup = additionalMeasurements.indexOf(meas.code) > -1 
                                        || this.variables.indexOf("_" + meas.code.replace('.', '_')) > -1;
//                                        || /s20.*/.test(meas.code)
                                    console.log('test showing in popup: ', "_" + meas.code.replace('.', '_'), meas.code)

                                    if (showInPopup) {
                                        console.log('should show in popup: ', meas, meas.code)
                                    } else {
                                        console.log('not showing ', meas, meas.code)
                                    }
                                    meas.showInPopup = showInPopup;

                                    return showInPopup;
                                }
                            )
                            //console.log('what are my columns 2: ', data.measurement.filter((meas) => meas.showInPopup))

                            data.measurement = data.measurement.concat(outsideMeasurements);

                            // and sort it 
                            //data.measurement = data.measurement.sort((a, b) => a.code > b.code);

                            //data.measurement = data.measurement.map((meas) => {return {...meas, code: meas.code.replace('.', '_')}})

                            console.log('my measurements: ', data.measurement)
                            var collCharts = buildVirtualMeasurements(data.collections, data.measurement);
                            console.log('my collections: ', data.collections, collCharts); 

                            // this is config, we can use it 
                            this.pmChartsConfig = collCharts.filter((coll, i) => coll.collection.length > 0).map((coll, i) => {
                                var { code, collection } = coll;
                                
                                // we assume that the number is the index in kml features table 
                                return {
                                    name: code,
                                    code: i,
                                    measurements: collection.map(
                                        (meas) => '_' + meas.code.replaceAll('.', '_')
                                    ).sort((a, b) => {return a < b ? 1 : (a > b) ? -1 : 0} )
                                }
                            })

                            // this is config, we can use it 
                            // this.pmChartsConfig = data.site.filter((site) => /p/.test(site.code)).map((site) => {
                            //     var { name, ds, code } = site;
                                
                            //     // we assume that the number is the index in kml features table 
                            //     return {
                            //         name,
                            //         code,
                            //         measurements: [ds.PM10, ds.PM25].map(
                            //             (code) => '_' + code
                            //         )
                            //     }
                            // })

                            console.log('what is my chart data: ', this.pmChartsConfig)

                            // set context immedietly after render function finishes
                            // i need to figure out how to communicate, maybe we should use redux or something 
                            setTimeout(() => {
                                this.ctx.setState(data);

                                // this is needed only if we update configs like pmChartsConfig 
                                // we should move pmChartsConfig to component consuming context (than we could remove it) 
                                this.forceUpdate();
                            }, 0);
                            //setTimeout(() => this.forceUpdate(), 1000);
                            //this.forceUpdate()

                            return null
                        }}
                    </Query>

                    {/* live data checker  */}
                    <LiveDataChecker
                        key="checker"
                        contextProvider={this.ctx}
                        dataUrl={this.props.dataUrl}
                        columns={this.variables}
                    ></LiveDataChecker>

                    {/* top bar for testing */}
                    <MeteoPanelContext.Consumer>
                        {(ctx) => {

                            let mode = ctx.ui.viewMode;

                            const buttonsHandler = (label) => {
                                ctx.setState({ 
                                    ui: { 
                                        charts: { 
                                            selectedRange: label 
                                        } 
                                    } 
                                });
                            };

                            const switchModeHandler = (ev) => {

                                if (mode == "default") {
                                    ctx.setState({
                                        ui: {
                                            viewMode: (mode == "default" ? "history" : "default")
                                        }
                                    })
                                }

                                if (mode == "history") {
                                    var now = new Date();
                                    var now1h = new Date(now);
                                    var uts = Math.round(now.getTime() / 1000);

                                    roundDate(now, '10m');
                                    roundDate(now1h, '1h')

                                    var uts10m = dateToUts(now);
                                    var uts1h = dateToUts(now1h)

                                    ctx.setState({
                                        now: uts, now10m: uts10m,
                                        ui: {
                                            historyMode: {
                                                now1h: uts1h
                                            }, 
                                            viewMode: (mode == "default" ? "history" : "default")
                                        }
                                    })
                                }


                            }

                            const changeDate = (ev, data) => {
                                let d = Moment(data.value).toDate();
                                let now1h = Math.floor(d.getTime()/1000);

                                console.log('evdate: ', data, data.value, Moment(data.value), d, d.getDate(), now1h)
                                
                                // we can assume that it works only in history mode 
                                ctx.setState({
                                    ui: {
                                        historyMode: { 
                                            now1h 
                                        }
                                    },
                                    now10m: now1h
                                })

                            }

                            let timestamp = ctx.now;

                            switch (ctx.ui.viewMode) {
                                case "history": 
                                    timestamp = ctx.ui.historyMode.now1h;
                                    break;
                                default: 
                            }


                            console.log('testing topbar: ', timestamp)
                            return (
                                <TopBar {...{
                                    mode: ctx.ui.viewMode, 
                                    timestamp,
                                    buttonsHandler, switchModeHandler, changeDate,
                                    selectedButton: ctx.ui.charts.selectedRange,
                                }} />
                            )
                        }}
                    </MeteoPanelContext.Consumer>
                    


                    {/* viewport grid, adjust rowHeight to the resolution */}
                    <GridLayout className="layout" cols={16} width={1900} rowHeight={280} isDraggable={false} isResizable={false}>

                        {/* just a map */}
                        <div key={"map"} data-grid={{ x: 0, y: 0, w: 8, h: 2, minW: 1, maxW: 8 }} style={{ /* border: '1px solid black'*/ borderRadius: 10, overflow: 'hidden' }}>
                            {/* wind rose */}
                            <div style={{ position: 'absolute', width: 200, height: 200, right: 2, bottom: 20, zIndex: 1000, opacity: 1}}>

                                <LiveWindBoard
                                    dataUrl={this.props.dataUrl}
                                />
                            </div>
                            {/* wind stats table, depends on data queried by wind rose component  */}
                            {/* <div style={{ position: 'absolute', width: 450, right: 2, bottom: 240, zIndex: 1000, opacity: 1}} className="meteo-wind-stats-table">
                                <WindStatsTable/>
                            </div> */}
                            <LocationsMap dataUrl={this.props.configUrl} />
                        </div>

                        {/* dynamic table with associated query */}
                        <div key={"params"} data-grid={{ x: 8, y: 0, w: 2, h: 2, maxW: 2 }} style={{ border: '0px solid black' }}>
                            <LiveDataTable
                                dataUrl={this.props.dataUrl}
                                defaultViewData={this.tableViewData}
                                viewConfig={this.tableViewConfig}
                                columns={this.variables}
                            /> 
                        </div>

                        {/* dynamic chart with associated query */}
                        <div key={"chart"} data-grid={{ x: 0, y: 3, w: 10, h: 1, minW: 10, maxW: 10 }} style={{ padding: 0 }}>

                            <MeteoPanelContext.Consumer key={"meteo_chart"}>
                                {(context) => {
                                    return (
                                        <LiveDataChart
                                            key={"meteo"}
                                            name={"meteo"}
                                            selectionData={this.ctx && this.ctx.state.ui.historyMode && this.ctx.state.ui.historyMode.selection}
                                            dataUrl={this.props.dataUrl}
                                            tabsConfig={this.tabsConfig}
                                            defaultTab={this.tabsConfig[0]}
                                        />
                                    )
                                }}
                            </MeteoPanelContext.Consumer>
                        </div>

                        {/* pm charts */}
                        <div key={"pm_charts_first"} data-grid={{ x: 11, y: 0, w: 6, h: 1, minW: 6, maxW: 6 }} style={{ padding: 0, zIndex: 10}}>
                            {
                                this.pmChartsConfig.length > 0 && [this.pmChartsConfig[0]].map((pm, i) => {
                                    return (
                                        <LiveDataChart
                                            key={"pm_" + i}
                                            name={"pm_" + i}
                                            onMouseMoveHandler={this.onFirstChartMouseMove.bind(this)}
                                            dataUrl={this.props.dataUrl}
                                            tabsConfig={[pm]}
                                            defaultTab={pm}
                                        />
                                    )
                                })
                            }
                        </div>
                        {/* if we want this part to be built based on changes in context, we need to wrap it inside Consumer 
                            but LiveDataChart is already wrapped  
                        */}



                        {/* pm charts */}

                        <div key={"pm_charts"} data-grid={{ x: 11, y: 1, w: 6, h: 2, minW: 6, maxW: 6 }} style={{ padding: 0, maxHeight: 600, overflowY: 'scroll' }}>
                            <MeteoPanelContext.Consumer key={"chart"}>
                                {(context) => {
                                    return this.pmChartsConfig.slice(1).map((pm, i) => {
                                        //console.log('what the fuck is going on historyMode: ', this.ctx.state.ui.historyMode.selection)
                                        return (
                                            <LiveDataChart
                                                selectionData={this.ctx.state.ui.historyMode && this.ctx.state.ui.historyMode.selection}
                                                name={"pm_" + i}
                                                key={"pm_" + i}
                                                dataUrl={this.props.dataUrl}
                                                tabsConfig={[pm]}
                                                defaultTab={pm}
                                            />
                                        )
                                    })
                                }}
                            </MeteoPanelContext.Consumer>
                        </div>
                    </GridLayout>
                </ApolloProvider>
            </MeteoPanelProvider>
        )
    }
}


export default MeteoPanel;
export { 
    client as apolloClient
};

